import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/browser";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { parseJwt } from "../../utils/jwt";

type ReactErrorBoundaryProps = {
  children?: ReactNode;
};

export const ReactErrorBoundary = (props: ReactErrorBoundaryProps) => {
  const { children } = props;
  const token = useAuthenticationStore((state) => state.token);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) => {
        Sentry.setTag("ReactErrorBoundary", true);
        Sentry.setTag("registrationId", parseJwt(token).registrationId);
        Sentry.captureException(error);
      }}
      onReset={() => {
        window.location.reload();
        window.location.href = "/register";
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
