import yup from "../../yup/yupInterface";
import { AttachmentRule } from "../../../config/model/TinRules";
import { IdentityConfirmationState, useTinsStore } from "../tins/tinsStore";

export const SPECIAL_UPLOAD_REQUIRED =
  "UPLOAD_REQUIRED_BECAUSE_OF_IDENTITY_CONFIRMATION_FAILURE";

const getAttachmentConditions = (attachmentRule: AttachmentRule) => {
  if (attachmentRule.visibleOn) {
    const [visibilityDeterminingField] = attachmentRule.visibleOn;
    return ["isResident", visibilityDeterminingField];
  }
  return ["isResident"];
};

const getErrorMessageForRequired = () =>
  useTinsStore.getState().identityConfirmationState ===
  IdentityConfirmationState.FAILED
    ? SPECIAL_UPLOAD_REQUIRED
    : "REQUIRED";

const visibleOnFunctionFulfilled = (
  value: string,
  attachmentRule: AttachmentRule
) => {
  if (attachmentRule.visibleOn) {
    const [, visibleRuleFunction] = attachmentRule.visibleOn;
    return visibleRuleFunction(value);
  }

  return true;
};

export const AttachmentSchema = (
  attachmentRule: AttachmentRule,
  documentIndex: number,
  attachmentIndex: number,
  fieldNamesPrefix: string,
  isResident: boolean
) => {
  return yup.object().shape({
    [`${fieldNamesPrefix}${documentIndex}-attachment${attachmentIndex}`]: yup
      .object()
      .when(getAttachmentConditions(attachmentRule), {
        is: (isResidentValue: boolean, value: string) => {
          return (
            isResidentValue === isResident &&
            visibleOnFunctionFulfilled(value, attachmentRule)
          );
        },
        then: (schema) =>
          schema.shape({
            file: attachmentRule.required
              ? yup
                  .mixed()
                  .matchesAttachmentExtensionRegex()
                  .hasCorrectSize()
                  .required(getErrorMessageForRequired())
              : yup.mixed().matchesAttachmentExtensionRegex().hasCorrectSize(),
            awsKey: yup.string(),
            wasUploaded: attachmentRule.required
              ? yup
                  .boolean()
                  .test("was-upload-validation", function validate(value) {
                    const { path, createError } = this;
                    return value
                      ? true
                      : createError({
                          path,
                          message: "FILE_NOT_UPLOADED",
                        });
                  })
              : yup.boolean(),
          }),
      }),
  });
};
